<template>
  <div class="t_study_nav">
    <div class="stu-module-header">
      <div class="stu-module-title">学习导航</div>
      <el-upload
          :action="`${url}hnjxjy-core/teacherCourse/learningNavigationByCourseId`"
          :data="{
          courseId: course ? course.id : null,
        }"
          :headers="{
          'x-token': auth['x-token'],
          'x-perm': auth['x-perm'],
        }"
          :on-success="onSuccess"
          :show-file-list="false"
          :on-progress="handleChange"
      >
        <el-button size="small" type="primary" @click="percent = 0;">上传材料</el-button>
        <p style="color: red;font-size: 14px; margin-left: 10px;margin-top: 10px; font-weight: bold;">支持jpg,png和office文件格式</p>
      </el-upload>
    </div>


    <el-dialog
        title="文件上传"
        :visible.sync="dialogVisible"
        width="30%"
        close-delay="false"
        :before-close="handleClose">
      <el-progress :text-inside="true" :stroke-width="24" :percentage="percent" status="success"></el-progress>
    </el-dialog>


    <div class="content" v-if="items">
      <template v-if="items.learnGuide != null">
        <div v-if="fileType === 0">
          <div style="height: 500px;">
            <div style="width: 98.9%;height: 50px;background: #2c3e50;position: relative;top:52px;left: 2px"></div>
            <iframe
                scrolling="auto"
                style="width: 100%;height: 100%;"
                :src="items.learnGuide">
            </iframe>
          </div>
        </div>
        <div v-if="fileType === 1">
          <div style="height: 500px;">
            <div style="width: 100%;height: 30px;background: #f3f2f1;position: relative;top:92px;left: 2px"></div>
            <iframe
                id="iframe"
                scrolling="auto"
                style="width: 100%;height: 100%;"
                :src="fileUrl">
            </iframe>
          </div>
        </div>
        <div v-if="fileType === 2">
          <img :src="items.learnGuide" alt="" width="600px" height="600px">
<!--               style="position: relative;left:calc(50% - 300px)">-->
        </div>
      </template>
    </div>
    <div v-else>请上传材料！</div>
  </div>
</template>

<script>
import {BASE_URL} from "@/libs/domain";
import PdfPreview from "@/components/student/PdfPreview";
import {ShowModifyCourseById} from "@/libs/api/teacher/study";
import mammoth from "mammoth";

export default {
  name: "t_study_nav",
  components: {PdfPreview},
  data() {
    return {
      boolItem: false,
      percent: 0,
      dialogVisible: false,
      vHtml: "",
      fileList: [],
      fileType: null,
      fileUrl: "",
      items: null,
      numPages: null, // pdf 总页数
      docDialog: false,
      wordText: ''
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    url() {
      return BASE_URL;
    },
    // fileType() {
    //   const learnGuide = this.items.learnGuide;
    //   if (!learnGuide) return;
    //   const type = learnGuide.substr(learnGuide.length - 4, learnGuide.length);
    //   // if (type === ".pdf") return "pdf";
    //   // if (".jpg.jpeg.png.JPG.JPEG.PNG.gif.docx".includes(type)) return "image";
    // },
  },
  activated() {
    this.init();
  },
  methods: {
    handleChange() {
      this.dialogVisible = true;
      this.boolItem = false;
      this.recursivePercent();
    },
    recursivePercent() {
      if (this.boolItem) {
        return;
      }
      const p = this.percent;
      let addPercent = Math.round(Math.random() * 4) + 1;
      if (p <= 94) {
        this.percent = this.percent + addPercent;
      }
      if (this.percent >= 100) {
        return;
      }
      setTimeout(() => {
        this.recursivePercent();
      }, 1000);
    },
    handleClose() {

    },
    init() {
      this.items = null;
      this.openLoadingView();
      let number = Math.round(Math.random() * 5 * 1000);
      setTimeout(() => {
        ShowModifyCourseById({id: this.course.id})
            .then((res) => {
              this.items = res.data;
              if (this.items.learnGuide) this.loadingView.close();
              if (null != this.items.learnGuide && this.items.learnGuide !== "") {
                let learnGuideArray = this.items.learnGuide.split(".");
                let suffix = learnGuideArray[learnGuideArray.length - 1];
                if (suffix === "pdf" || suffix === "PDF") {
                  this.fileType = 0;
                }
                if (suffix === "doc" || suffix === "DOC" || suffix === "docx" || suffix === "DOCX") {
                  this.fileType = 1;
                  this.fileUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + this.items.learnGuide;
                }
                let imgList = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG', 'bmp', 'BMP', 'gif', 'GIF'];
                let result = imgList.some(function (item) {
                  return item === suffix;
                });
                if (result) {
                  this.fileType = 2;
                }
              } else {
              }
            }).catch((err) => {
        });
      }, number);
    },
    onSuccess(res) {
      if (res.code === 2000) {
        this.percent = 100;
        setTimeout(() => {
          this.dialogVisible = false;
          this.boolItem = true;
          this.recursivePercent();
        }, 1000);
        this.$message.success("上传材料成功");
        this.init( );
      } else {
        this.$message.error(
            res.data && res.data.message ? res.data.message : res.message
        );
        setTimeout(() => {
          this.dialogVisible = false;
          this.percent = 0;
          this.boolItem = true;
          this.recursivePercent();
        }, 1000);
        this.percent = 0;
      }
    },
  },
};
</script>

<style>
#iframe #WACRibbonPanel {
  display: none
}

#WACRibbonPanel {
  display: none
}
</style>
<style lang="less" scoped>
.t_study_nav {
  box-shadow: @shadow;
  background: #fff;

  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }

  .content {
    padding: 20px;
    height: 600px;
  }
}
</style>
