import request from '../../request'
import qs from 'qs'

// 查询学习导航
export const ShowModifyCourseById = (p) => {
    return request.get(`eduCourse/showModifyCourseById?${qs.stringify(p)}`).then(res => res.data)
}


// 我的课程
export const TeacherCourseList = (p) => {
    return request.get(`teacherCourse/list?${qs.stringify(p)}`).then(res=>res.data)
}